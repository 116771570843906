.root {
  background: black;
}

.Countdown {
  height: var(--100vh);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CountdownFilter1,
.CountdownFilter2,
.CountdownFilter3,
.CountdownBackground1,
.CountdownBackground2,
.CountdownBackground3,
.CountdownBackground4 {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.CountdownFilter1 {
  animation: countdown-1 13s ease-in-out infinite;
}

.CountdownFilter2 {
  animation: countdown-2 11s ease-in-out infinite;
}

.CountdownFilter3 {
  animation: countdown-3 7s ease-in-out infinite;
}

.CountdownBackground1 {
  background: url(../../img/FiraB_Street.png) center center / cover;
  animation: countdown-bg-1 12s ease-in-out infinite;
}

.CountdownBackground2 {
  background: url(../../img/FiraB_Church.png) center center / cover;
  animation: countdown-bg-2 12s ease-in-out infinite;
}

.CountdownBackground3 {
  background: url(../../img/FiraB_Mask.png) center center / cover;
  animation: countdown-bg-3 12s ease-in-out infinite;
}

@keyframes countdown-bg-1 {
  0% { opacity: 0; }
  2% { opacity: 1; }
  25% { opacity: 1; }
  35% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes countdown-bg-2 {
  0% { opacity: 0; }
  30% { opacity: 0; }
  40% { opacity: 1; }
  65% { opacity: 1; }
  67% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes countdown-bg-3 {
  0% { opacity: 1; }
  5% { opacity: 1; }
  10% { opacity: 0; }
  65% { opacity: 0; }
  75% { opacity: 1; }
  100% { opacity: 1; }
}

@keyframes countdown-3 {
  0% { filter: contrast(0.9); }
  20% { filter: contrast(1.5); }
  30% { filter: contrast(1.0); }
  50% { filter: contrast(1.4); }
  60% { filter: contrast(0.9); }
  80% { filter: contrast(1.3); }
  90% { filter: contrast(1.2); }
  100% { filter: contrast(0.9); }
}

@keyframes countdown-2 {
  0% { filter: saturate(0.5) sepia(0.5); }
  20% { filter: saturate(0.4) sepia(0.4); }
  30% { filter: saturate(0.7) sepia(0.6); }
  50% { filter: saturate(0.5) sepia(0.3); }
  80% { filter: saturate(0.6) sepia(0.2); }
  100% { filter: saturate(0.4) sepia(0.3); }
}

@keyframes countdown-1 {
  0% { filter: brightness(0.6); }
  5% { filter: brightness(0.9); }
  25% { filter: brightness(0.5); }
  45% { filter: brightness(0.4); }
  50% { filter: brightness(0.7); }
  55% { filter: brightness(0.4); }
  75% { filter: brightness(0.8); }
  95% { filter: brightness(0.5); }
  100% { filter: brightness(0.6); }
}

.CountdownTargets {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.CountdownTarget {
  padding: 5vh 0 0;
  color: white;
  text-align: center;
  font-family: "Inter";
  text-transform: uppercase;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  height: 90px;
  letter-spacing: 0.2em;
}

.CountdownNumbers {
  flex: 1;
  padding: 8vh 20vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

@media (min-width: 768px) {
  .CountdownNumbers {
    flex-direction: row;
  }
}

.CountdownSegment {
  position: relative;
  width: 80px;
  text-align: center;
  line-height: 64px;
  height: 64px;
}

.CountdownValue {
  color: white;
  position: absolute;
  inset: 0;
  font-family: "Sigurd";
  font-size: 64px;
}

.CountdownSeparator {
  color: white;
  font-family: "Sigurd";
  font-size: 48px;
  height: 48px;
  line-height: 40px;
  vertical-align: middle;
}

.Canvas {
  height: var(--100vh);
  position: absolute;
  inset: 0;
}

.CountdownFooter {
  padding: 0 32px 5vh;
}

.CountdownButtons {
  display: flex;
  justify-content: center;
}

.CountdownButtons .CountdownLogin,
.CountdownButtons .CountdownRegister {
  margin: 0 12px;
  border-radius: 0;
  display: block;
  width: 140px;
  height: 45px;
  line-height: 45px;
  background: #3333;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0.75px -3px 6.75px 0px #2F2F2F66 inset;
}