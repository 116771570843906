.LoginPage {
  min-height: var(--100vh);
  padding: 48px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.LoginHeader {
  height: 4rem;
}

.LoginLogo {
  height: 100%;
  width: auto;
}

.LoginFooter {
  height: 20px;
  display: flex;
}

.LoginSocials {
  height: 20px;
  margin: 0 12px;
}

.SplashScreen,
.LoginScreen,
.RegisterScreen {
  position: relative;
  height: 456px;
  width: calc(100vw - 96px);
  max-width: 500px;
  padding: 48px 48px 16px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginForm,
.RegisterForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* TRANSITIONS FOR LOGIN BUTTONS */

.LoginPage .LoginButton,
.LoginPage .RegisterButton {
  position: absolute;
  transition: var(--button-transition), top var(--transition-duration), opacity var(--transition-duration), border var(--transition-duration);
  opacity: 1;
}

/* Place clicked buttons on top and manually apply variant=none on them */
.LoginPage[data-pathname^='/login'] .LoginContent-exit-active .SplashScreen .LoginButton,
.LoginPage[data-pathname^='/login'] .LoginScreen .LoginButton,
.LoginPage[data-pathname^='/register'] .LoginContent-exit-active .SplashScreen .RegisterButton,
.LoginPage[data-pathname^='/register'] .RegisterScreen .RegisterButton {
  top: 0;
  background-color: transparent;
  box-shadow: none;
  backdrop-filter: none;
  border: 1px solid transparent;
}

/* After navigating, hide those buttons that haven't been clicked */
.LoginPage[data-pathname^='/login'] .LoginContent-exit-active .SplashScreen .RegisterButton,
.LoginPage[data-pathname^='/login'] .LoginScreen .RegisterButton,
.LoginPage[data-pathname^='/register'] .LoginContent-exit-active .SplashScreen .LoginButton,
.LoginPage[data-pathname^='/register'] .RegisterScreen .LoginButton {
  opacity: 0;
}

/* Disable unused buttons after transition ends */
.LoginPage[data-pathname^='/login'] .LoginContent-enter-done .LoginScreen .RegisterButton,
.LoginPage[data-pathname^='/register'] .LoginContent-enter-done .RegisterScreen .LoginButton {
  visibility: hidden;
}

/* Place buttons back in middle when navigating back  */
.LoginButton,
.LoginPage[data-pathname='/'] .LoginContent-exit-active .LoginScreen .LoginButton {
  top: calc(50% - var(--button-height) * 1.25);
}

.RegisterButton,
.LoginPage[data-pathname='/'] .LoginContent-exit-active .RegisterScreen .RegisterButton {
  top: calc(50% + var(--button-height) * 0.25);
}

/* ANIMATIONS FOR BACK BUTTON */

.LoginPage .LoginBackButton {
  position: absolute;
  top: 0;
}

.LoginArrow {
  padding: 16px;
}

@keyframes arrow-slide-in {
  from { left: calc(50% - 50vw - 40px); }
  to { left: 0; }
}

.LoginPage[data-pathname^='/login'] .LoginScreen .LoginBackButton,
.LoginPage[data-pathname^='/register'] .RegisterScreen .LoginBackButton {
  animation: var(--transition-duration) ease-out 0s arrow-slide-in;
  left: 0;
}

@keyframes arrow-slide-out {
  from { left: 0; }
  to { left: calc(50% - 50vw - 40px); }
}

.LoginPage[data-pathname='/'] .LoginScreen .LoginBackButton,
.LoginPage[data-pathname='/'] .RegisterScreen .LoginBackButton {
  animation: var(--transition-duration) ease-in 0s arrow-slide-out;
  left: calc(50% - 50vw - 40px);
}


/* ANIMATIONS FOR CONTENT */

@keyframes content-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

.LoginPage[data-pathname^='/login'] .LoginScreen .LoginForm,
.LoginPage[data-pathname^='/register'] .RegisterScreen .RegisterForm {
  animation: var(--transition-duration) ease-out 0s content-fade-in;
}

@keyframes content-fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}

.LoginPage[data-pathname='/'] .LoginScreen .LoginForm,
.LoginPage[data-pathname='/'] .RegisterScreen .RegisterForm {
  animation: var(--transition-duration) ease-in 0s content-fade-out;
  opacity: 0;
}