.Space.xs {
  width: 12px;
  height: 12px;
}
.Space.s {
  width: 16px;
  height: 16px;
}

.Space.m {
  width: 24px;
  height: 24px;
}

.Space.l {
  width: 32px;
  height: 32px;
}

.Space.xl {
  width: 48px;
  height: 48px;
}

.Space.xxl {
  width: 64px;
  height: 64px;
}