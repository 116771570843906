.EventsPage {
  height: var(--100vh);
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.EventsFooter {
  height: 48px;
  margin-top: 32px;
}

.EventsLogo {
  height: 100%;
  width: auto;
}

.EventsContent {
  overflow-x: hidden;
  width: 100%;
  padding: 0 48px;
}

@media (min-width: 596px) {
  .EventsContent {
    padding: 0 calc(50vw - 250px);
  }
}

.EventsContent-exit-active {
  opacity: 0;
  transition: opacity var(--transition-duration);
}

.EventsContent-enter {
  opacity: 0;
}

.EventsContent-enter-active {
  opacity: 1;
  transition: opacity var(--transition-duration);
}

.EventsListTitle {
  color: white;
  font-family: "Mongolian Baiti";
  text-transform: uppercase;
  margin: 0;
  font-size: 32px;
  line-height: 32px;
}

.EventsListScreen {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.EventCard {
  position: relative;
  width: 100%;
  height: 200px;
  display: block;
}

.EventCardCover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
  transition: filter 500ms ease-out;
}

.EventCard:hover .EventCardCover {
  filter: none;
}

.EventCardContent {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 113.73%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: backdrop-filter 500ms ease-out;
  backdrop-filter: blur(4px);
}

.EventCard:hover .EventCardContent {
  backdrop-filter: none;
}

.EventCardDetails {
  font-family: "Mongolian Baiti";
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.05em;
  padding: 12px;
  color: #ffffff;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  text-align: center;
}

.EventCardTitle {
  font-family: "Alte Schwabacher";
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #ffffff;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-align: center;
}

.EventsBackButton {
  display: block;
  padding: 6px 0;
}

.EventDetails {
  color: white;
  text-align: center;
  font-family: "Mongolian Baiti";
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.EventTitle {
  font-family: "Alte Schwabacher";
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-align: center;
  margin: 0;
}

.EventDescription {
  margin: 0;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.EventPlayer {
  position: relative;
}

.VideoCover {
  width: 100%;
  height: auto;
}

.VideoCode {
  position: absolute;
  width: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.GalleryImage {
  width: 100%;
  height: auto;
}
