@font-face {
  font-family: 'Alte Schwabacher';
  src: local('Alte Schwabacher'), url(../../img/AlteSchwabacher-lgw0.ttf) format('truetype');
}

@font-face {
  font-family: 'Mongolian Baiti';
  src: local('Mongolian Baiti'), url(../../img/monbaiti.ttf) format('truetype');
}

:root {
  --100vh: 100vh;
  --transition-duration: 400ms;
  --button-height: 56px;
  --button-transition: background-color var(--transition-duration), box-shadow var(--transition-duration);
}

html {
  background-color: black;
}

.App {
  min-height: var(--100vh);
  background-color: black;
}
