.Checkbox {
  padding-left: 24px;
  display: block;
}

.CheckboxBox {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 1.5px solid white;
  border-radius: 50%;
  vertical-align: middle;
  margin-left: -24px;
  margin-right: 8px;
}

.CheckboxBox:checked {
  background-color: white;
}

.CheckboxLabel {
  font-size: 12px;
  line-height: 12px;
  color: white;
  opacity: 0.6;
}

