.CartPage {
  height: var(--100vh);
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.CartContent {
  overflow-x: hidden;
  width: 100%;
  padding: 0 48px;
}

@media (min-width: 596px) {
  .CartContent {
    padding: 0 calc(50vw - 250px);
  }
}

.CartContent-exit-active {
  opacity: 0;
  transition: opacity var(--transition-duration);
}

.CartContent-enter {
  opacity: 0;
}

.CartContent-enter-active {
  opacity: 1;
  transition: opacity var(--transition-duration);
}

.CartListTitle {
  color: white;
  font-family: "Mongolian Baiti";
  text-transform: uppercase;
  margin: 0;
  font-size: 32px;
  line-height: 32px;
}

.CartListInfo {
  display: block;
  width: 100%;
  color: white;
  font-family: Arial, sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
}

.CartListScreen {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CartCard {
  position: relative;
  width: 100%;
  display: flex;
  gap: 32px;
  align-items: start;
  justify-content: space-between;
}

.CartCardCover {
  width: 64px;
  height: 92px;
  object-fit: cover;
  object-position: top;
}

.CartCardContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;
}

.CartCard:hover .CartCardContent {
  backdrop-filter: none;
}

.CartCardDetails {
}

.CartCardName {
  font-family: Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #ffffff;
  display: block;
}

.CartCardPrice {
  display: inline-block;
  font-family: Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.05em;
  padding: 4px 8px;
  white-space: nowrap;
  color: black;
  background: white;
}

.CartRemoveIcon {
  width: 24px;
  height: auto;
}

.CartCardTitle {
  font-family: "Alte Schwabacher";
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.CartBackButton {
  display: block;
  padding: 6px 0;
}

.CartDetails {
  color: white;
  text-align: center;
  font-family: "Mongolian Baiti";
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.CartTitle {
  font-family: "Mongolian Baiti";
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-align: center;
  margin: 0;
}

.CartCover {
  width: 100%;
  height: auto;
}

.CartDescription {
  margin: 0;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.CartTitle {
  font-family: "Mongolian Baiti";
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}

.CartOptions {
width: 250px;
}

.CartOption {
}

.CartForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CartVatPrice {
  display: block;
  width: 100%;
  height: var(--button-height);
  line-height: var(--button-height);
  font-family: Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 0 8px;
  white-space: nowrap;
  color: white;
}

.CartTotalPrice {
  display: block;
  width: 100%;
  height: var(--button-height);
  line-height: var(--button-height);
  font-family: Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 0 8px;
  white-space: nowrap;
  color: black;
  background: white;
}

.CartListScreen .CartCheckoutButton {
  width: 100%;
}
