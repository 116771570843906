.Button {
  height: var(--button-height);
  line-height: var(--button-height);
  border: 1px solid transparent;
  padding: 0 8px;
  font-family: "Mongolian Baiti";
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: var(--button-transition);
  white-space: nowrap;
}

.Button:not([disabled]) {
  cursor: pointer;
}

.Button.primary,
.Button.secondary,
.Button.tertiary {
  width: 178px;
  backdrop-filter: blur(2px);
  box-shadow: inset 1px -4px 9px rgba(47, 47, 47, 0.4);
  border: 1px solid white;
}

.Button.primary {
  background-color: rgba(227, 227, 227, 0.05);
  color: #ffffff;
}

.Button.secondary {
  background-color: white;
  color: black;
}

.Button.tertiary {
  border: 1px solid transparent;
  background: #930124;
  color: black;
}

.Button.small {
  height: calc(var(--button-height) / 2);
  line-height: calc(var(--button-height) / 2);
  border: 1.5px solid white;
  background: transparent;
  color: white;
  font-size: 13px;
  line-height: 13px;
  padding: 6px 8px;
}
