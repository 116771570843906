.Input {
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1.5px solid white;
  color: white;
  text-align: center;
  font-size: 15px;
  width: 100%;
  max-width: 300px;
  padding: 12px;
  border-radius: 0;
}

.Input::placeholder {
  opacity: 0.6;
  font-size: 12px;
  font-style: italic;
}

