.Select {
  background: transparent;
  outline: none;
  border: 1px solid white;
  color: white;
  max-width: 300px;
  width: 100%;
  border-radius: 0;

  height: var(--button-height);
  line-height: var(--button-height);
  padding: 0 8px;
  font-family: "Mongolian Baiti";
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  white-space: nowrap;
}
