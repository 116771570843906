.MerchPage {
  height: var(--100vh);
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.MerchContent {
  overflow-x: hidden;
  width: 100%;
  padding: 0 48px;
}

@media (min-width: 596px) {
  .MerchContent {
    padding: 0 calc(50vw - 250px);
  }
}

.MerchContent-exit-active {
  opacity: 0;
  transition: opacity var(--transition-duration);
}

.MerchContent-enter {
  opacity: 0;
}

.MerchContent-enter-active {
  opacity: 1;
  transition: opacity var(--transition-duration);
}

.MerchListTitle {
  color: white;
  font-family: "Mongolian Baiti";
  text-transform: uppercase;
  margin: 0;
  font-size: 32px;
  line-height: 32px;
}

.MerchListInfo {
  display: block;
  width: 100%;
  color: white;
  font-family: Arial, sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
}

.MerchListScreen {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MerchCard {
  position: relative;
  width: 100%;
  display: block;
}

.MerchCardCover {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: top;
}

.MerchCardContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;
}

.MerchCard:hover .MerchCardContent {
  backdrop-filter: none;
}

.MerchCardDetails {
}

.MerchCardName {
  font-family: Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #ffffff;
  display: block;
}

.MerchCardPrice {
  display: inline-block;
  font-family: Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.05em;
  padding: 4px 8px;
  white-space: nowrap;
  color: black;
  background: white;
}

.MerchCardTitle {
  font-family: "Alte Schwabacher";
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.MerchBackButton {
  display: block;
  padding: 6px 0;
}

.MerchDetails {
  color: white;
  text-align: center;
  font-family: "Mongolian Baiti";
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.MerchTitle {
  font-family: "Mongolian Baiti";
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-align: center;
  margin: 0;
}

.MerchCover {
  width: 100%;
  height: auto;
}

.MerchDescription {
  margin: 0;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.MerchOptions {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.MerchOption {
  display: block;
  flex: 1;
}

.MerchAddPrice {
  display: block;
  width: 100%;
  height: var(--button-height);
  line-height: var(--button-height);
  font-family: Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 0 8px;
  white-space: nowrap;
  color: black;
  background: white;
}

.MerchDetailScreen .MerchAddButton {
  width: 100%;
}
