.MenuHeader {
  position: relative;
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 80px;
  align-items: flex-start;
  transition: height 0.5s ease;
}

.MenuHeader.MenuVisible {
  height: 224px;
}

.MenuIcon {
  position: absolute;
  top: 32px;
  right: 0;
}

.MenuLink {
  position: relative;
  color: white;
  font-family: 'Mongolian Baiti';
  text-transform: uppercase;
  margin: 0 0 16px;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
}

.MenuLink::after {
  content: '';
  width: 0;
  height: 1px;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  animation: underline 0.5s ease forwards;
}

.MenuLink:nth-of-type(1)::after {
  animation-delay: 0.1s;
}

.MenuLink:nth-of-type(2)::after {
  animation-delay: 0.2s;
}

.MenuLink:nth-of-type(3)::after {
  animation-delay: 0.3s;
}

.MenuLink:nth-of-type(4)::after {
  animation-delay: 0.4s;
}

.MenuLink:nth-of-type(5)::after {
  animation-delay: 0.5s;
}

@keyframes underline {
  to{
    width: 100%;
  }
}

.MerchLink {
  color: #930124;
}