.Link {
  text-decoration: none;
  color: inherit;
}

.Link.text {
  text-decoration: underline;
}

.Link.link {
  text-decoration: none;
  color: white;
  border-bottom: 2px solid #FFFFFF;
  padding: 2px;

  font-family: 'Mongolian Baiti';
  font-style: italic;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.05em;
}